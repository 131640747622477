import { intlFormat, parseISO } from "date-fns";

import { DEFAULT_LANGUAGE, EMPTY_VALUE_PLACEHOLDER } from "./constants";
import { getCurrencySymbol } from "./utils";

export const regionFormatter = (value: string, lng: string | undefined) => {
    const formatter = new Intl.DisplayNames([lng ?? DEFAULT_LANGUAGE], { type: "region" });
    return formatter.of(value.toUpperCase()) ?? value;
};

const NUMERIC_DATE_FORMAT: Intl.DateTimeFormatOptions = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
} as const;

export const localDateFormatter = (value: string | number | Date, lng: string | undefined) => {
    if (value === undefined) return EMPTY_VALUE_PLACEHOLDER;

    const date = value instanceof Date ? value : parseISO(typeof value === "string" ? value : value.toString());
    const locale = lng ?? DEFAULT_LANGUAGE;

    return intlFormat(date, NUMERIC_DATE_FORMAT, { locale });
};

export const ordinalFormatter = (value: number, lng: string | undefined) => {
    if (lng !== "en") {
        return `${value}.`;
    }

    if (value >= 10 && value <= 20) {
        return `${value}th`;
    }

    switch (value % 10) {
        case 1:
            return `${value}st`;
        case 2:
            return `${value}nd`;
        case 3:
            return `${value}rd`;
        default:
            return `${value}th`;
    }
};

const CURRENCY_FORMAT: Intl.NumberFormatOptions = {
    style: "decimal",
    maximumFractionDigits: 2,
    useGrouping: true,
} as const;

export const currencyFormatter = (value: number | undefined, lng: string | undefined, options: { currency: string }) => {
    if (value === undefined) return EMPTY_VALUE_PLACEHOLDER;
    const formatter = new Intl.NumberFormat(lng ?? DEFAULT_LANGUAGE, CURRENCY_FORMAT);
    const formattedValue = formatter.format(value);
    if (!options.currency) return formattedValue;
    return `${formattedValue} ${getCurrencySymbol(options.currency)}`;
};

const PERCENT_FORMAT: Intl.NumberFormatOptions = {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} as const;

export const percentFormatter = (value: number | undefined, lng: string | undefined) => {
    if (value === undefined) return EMPTY_VALUE_PLACEHOLDER;
    const formatter = new Intl.NumberFormat(lng ?? DEFAULT_LANGUAGE, PERCENT_FORMAT);
    return formatter.format(value / 100);
};
