import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { IconWithPopover, Progress } from "src/components";
import { getCurrencyConversionRate } from "src/config";
import { InfoIcon } from "src/icons";
import { getUserCurrency, useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { Trans, useTranslation } from "src/translations";
import type { ProjectInfo } from "src/types";
import { convertPriceToTargetCurrency, getCurrentNominalPrice, getValOrThrowError, isNotNullish, useAppSelector } from "src/utils";
import { calculateRemainingPrice, calculateSharePercentage } from "../../utils";

type ProjectDetailTimelineProps = Readonly<
    {
        loading: boolean;
    } & Partial<ProjectInfo["project"]>
>;

export const ProjectDetailTimeline: FC<ProjectDetailTimelineProps> = ({
    documentId,
    currency,
    loading,
    tokenTotalCount,
    tokenCurrentNominalPrice,
    tokenTotalPrice,
}) => {
    const { t } = useTranslation();
    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);

    const { data: availableTokensCount, isLoading: tokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(documentId!);
    const isLoading = loading || tokensLoading;

    const currentNominalPrice = getCurrentNominalPrice(tokenCurrentNominalPrice);
    const investedPercent =
        isNotNullish(availableTokensCount) && isNotNullish(tokenTotalCount)
            ? calculateSharePercentage(availableTokensCount, tokenTotalCount)
            : 0;

    const remainingAmount = calculateRemainingPrice(
        tokenTotalPrice ?? 0,
        tokenTotalCount ?? 0,
        availableTokensCount ?? 0,
        currentNominalPrice ?? 0,
    );

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between gap-2 text-base">
                <span>
                    {isLoading ? (
                        <Skeleton width={144} />
                    ) : (
                        <Trans
                            i18nKey="projects.detail.timeline.invested"
                            values={{
                                investedPercent: t("format.percent", { value: investedPercent }),
                            }}
                            components={[<strong className="text-brand" />]}
                        />
                    )}
                </span>
                <span className="inline-flex gap-1">
                    {isLoading ? (
                        <Skeleton width={160} />
                    ) : (
                        t("projects.detail.timeline.remaining.label", {
                            remainingAmount: t("format.amount", {
                                value: convertPriceToTargetCurrency(
                                    remainingAmount,
                                    getValOrThrowError(currency),
                                    userCurrency,
                                    conversionRate,
                                ),
                            }),
                            unit: userCurrency,
                        })
                    )}
                    <IconWithPopover icon={<InfoIcon className="size-4 fill-secondary" />}>
                        {t("projects.detail.timeline.remaining.text")}
                    </IconWithPopover>
                </span>
            </div>
            <Progress progress={investedPercent} />
        </div>
    );
};
